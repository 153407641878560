export const seo = {
  url: 'csr',
  title: {
    pl: 'CSR - działalność organizacji Omida',
    en: 'CSR - organization activities Omida',
  },
  desc: {
    pl: 'Omida od lat wspiera działalność społeczną i kulturową. Poznaj obszary wspierane przez jedną z najbardziej rozpoznawalnych organizacji TSL - Omida VLS',
    en: 'Omida has been supporting social and cultural activities for many years. Discover the areas supported by one of the most recognizable TSL organizations - Omida VLS',
  },
  keywords: ['omida', 'logistics', 'transport'],
}

export const intro = {
  title: {
    pl: 'CSR',
    en: 'CSR',
  },
  desc: {
    pl: 'Omida od lat wspiera działalność społeczną i kulturową. Poznaj obszary wspierane przez jedną z najbardziej rozpoznawalnych organizacji TSL - Omida VLS',
    en: 'Omida has been supporting social and cultural activities for many years. Discover the areas supported by one of the most recognizable TSL organizations - Omida VLS',
  },
  button: {
    text: {
      pl: 'Czytaj więcej',
      en: 'Read more',
    },
    action: 'SCROLL',
  },
}

export const main = {
  body: [
    {
      headline: {
        pl: 'Czym jest CSR - Corporate Social Responsibility?',
        en: 'What CSR - Corporate Social Responsibility means?',
      },
      texts: [
        {
          pl: `<span><strong>Społeczna odpowiedzialność</strong> jest elementem strategii Omida VLS Sp. z o.o.
      Dlatego w codziennej pracy i planach strategicznych chcemy łączyć realizację celów biznesowych z działaniami, które odpowiadają na <strong>zróżnicowane potrzeby wszystkich</strong>: pracowników, klientów, partnerów biznesowych i akcjonariuszy.
      </span>`,
          en: `<span><strong>Social responsibility</strong> is a key factor in Omida's strategy. Therefore, in our daily work and strategic plans, we want to combine the achievement of business goals with activities that respond to <strong>the diverse needs of everyone</strong>: employees, customers, business partners and shareholders.
      </span>`,
        },
      ],
    },
    {
      headline: {
        pl: 'Działalność CSR Omida VLS',
        en: 'CSR activities of Omida Logisitcs',
      },
      texts: [
        {
          pl: `<span>Społeczna odpowiedzialność biznesu to podejście biznesowe, które przyczynia się do zrównoważonego rozwoju, zapewniając korzyści ekonomiczne, społeczne i środowiskowe dla wszystkich interesariuszy. Społeczna odpowiedzialność biznesu, w skrócie CSR, obejmuje różne sektory, takie jak edukacja, zdrowie, zrównoważony rozwój środowiska, rozwój społeczności, czy rozwój obszarów wiejskich. Poniżej podane są szczegóły, dotyczące projektów z zakresu CSR, które wykonała firma Omida VLS Sp. z o.o. w ostatnich latach.
        </span>`,
          en: `<span>
          Corporate social responsibility is a business approach that contributes to sustainable development by providing economic, social and environmental benefits for all stakeholders. Corporate social responsibility, or CSR for short, covers various sectors such as education, health, environmental sustainability, community development and rural development. Details of the CSR projects carried out by Omida VLS Sp. z o.o. are given below. In recent years.
      </span>`,
        },
      ],
    },
  ],
}

export const features = [
  {
    id: 'objective',
    icon: 'CSR_objective',
    subtitle: {
      pl: 'Cele',
      en: 'Social',
    },
    title: {
      pl: 'Społeczne',
      en: 'Goals',
    },
    desc: {
      pl: 'Realizacja celów społecznych nie do osiągnięcia bez wsparcia ze strony biznesu',
      en: 'Realization of social goals impossible to achieve without business support',
    },
  },
  {
    id: 'social',
    icon: 'CSR_social',
    subtitle: {
      pl: 'Wsparcie',
      en: 'Social',
    },
    title: {
      pl: 'Socialne',
      en: 'Support',
    },
    desc: {
      pl: 'Wysoka jakość udogodnień socjalnych',
      en: 'High quality of social facilities',
    },
  },
  {
    id: 'support',
    icon: 'CSR_support',
    subtitle: {
      pl: 'Wsparcie',
      en: 'Support for',
    },
    title: {
      pl: 'Kulturowe',
      en: 'Culture',
    },
    desc: {
      pl: 'Wsparcie kulturowe lokalnych społeczności',
      en: 'Cultural support for local communities',
    },
  },
  {
    id: 'charitywork',
    icon: 'CSR_charitywork',
    subtitle: {
      pl: 'Działalność',
      en: 'Work for',
    },
    title: {
      pl: 'Charytatywna',
      en: 'Charity',
    },
    desc: {
      pl: 'Bezinteresowne niesienie pomocy jej potrzebującym',
      en: 'Helping those in need disinterestedly',
    },
  },
  {
    id: 'development',
    icon: 'CSR_development',
    subtitle: {
      pl: 'Rozwój zawodowy i ',
      en: 'Professional Development and',
    },
    title: {
      pl: 'Szkolenia',
      en: 'Training',
    },
    desc: {
      pl: 'Stały rozwój zawodowy dzięki dostępności szkoleń',
      en: 'Constant professional development thanks to the availability of training',
    },
  },
  {
    id: 'education',
    icon: 'CSR_education',
    subtitle: {
      pl: 'Rozwój młodzieży i',
      en: 'Youth Development and',
    },
    title: {
      pl: 'Edukacja',
      en: 'Education',
    },
    desc: {
      pl: 'Zaangażowanie w edukację i rozwój młodzieży',
      en: 'Involvement in education and youth development',
    },
  },
  {
    id: 'ecology',
    icon: 'CSR_ecology',
    subtitle: {
      pl: 'Dbałość o',
      en: 'Care for the',
    },
    title: {
      pl: 'Środowisko',
      en: 'Environment',
    },
    desc: {
      pl: 'Ograniczenie negatywnego wpływu przedsiębiorstw na środowisko',
      en: 'Limiting the negative impact of enterprises on the environment',
    },
  },
  {
    id: 'waste',
    icon: 'CSR_waste',
    subtitle: {
      pl: 'Gospodarowanie',
      en: 'Managing the',
    },
    title: {
      pl: 'Odpadami',
      en: 'Waste',
    },
    desc: {
      pl: 'Racjonalne gospodarowanie zasobami naturalnymi i odpadami',
      en: 'Rational management of natural resources and waste',
    },
  },
  {
    id: 'ecology2',
    icon: 'CSR_ecology2',
    subtitle: {
      pl: 'Działalność',
      en: 'Activity',
    },
    title: {
      pl: 'Proekologiczna',
      en: 'Pro-ecological',
    },
    desc: {
      pl: 'Zaangażowanie partnerów biznesowych w ramach łańcucha odpowiedzialności środowiskowej oraz inicjowanie wspólnych działań proekologicznych',
      en: 'Involving business partners in the chain of environmental responsibility and initiating joint pro-ecological activities',
    },
  },
]
